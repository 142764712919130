import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeetings(ctx, data) {
      return useJwt.getMeetings(data).then(response => response);
    },
    getMeetingsForWorkLog(ctx, workLogId) {
      return useJwt.getMeetingsFromWorkLog(workLogId).then(response => response);
    },

  },
};
