/* eslint-disable camelcase */
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { getUserRole } from '@/auth/utils';
import historyStoreModule from '../historyStoreModule';

export default function useHistoryList() {
  const APARTMENT_STORE_MODULE_NAME = "history";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, historyStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    province: null,
    district: null,
    ward: null,
    address: '',
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Bác sĩ',
      field: 'doctor',
    },
    {
      label: 'Bệnh nhân',
      field: 'patient',
    },
    {
      label: 'Thời gian khám',
      field: 'duration',
    },
    {
      label: 'Trạng thái',
      field: 'status',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const patient = ref(null);
  const doctor = ref(null);
  const medicalFacility = ref(null);
  const status = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    skipCount: 0,
    maxResultCount: 10,
  });

  //   API Call

  const fetchMeetings = () => {
    store
      .dispatch('history/fetchMeetings', serverParams.value)
      .then(response => {
        const { items, totalCount } = response.data;
        totalRecords.value = totalCount;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchMeetings();
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ skipCount: (params.currentPage - 1) * serverParams.value.maxResultCount });
  };

  const onPerPageChange = params => {
    updateParams({ maxResultCount: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ filter: val });
  });
  watch(doctor, val => {
    updateParams({ doctorId: val ? val._id : null });
  });
  watch(medicalFacility, val => {
    updateParams({ medical_facility_id: val ? val._id : null });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : null });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'end') return { variant: 'primary', title: "Đã khám", icon: 'SmileIcon' };
    if (val === 'created') return { variant: 'secondary', title: "Chờ khám", icon: 'SmileIcon' };
    if (val === 'live') return { variant: 'success', title: "Đang khám", icon: 'SmileIcon' };
    return { variant: 'warning', title: "Đã hủy", icon: 'MehIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const resolveTimeLength = val => {
    let { end_at } = val;
    if (!end_at && val.prescription) {
      end_at = val.prescription.created_at;
    }

    const seconds = moment(val.end_at).diff(moment(val.created_at), 'seconds');
    const hours = parseInt(seconds / 3600, 10);
    const remainMinutes = parseInt((seconds - hours * 3600) / 60, 10);
    const remainSeconds = seconds - hours * 3600 - remainMinutes * 60;

    const timeArr = [];

    if (hours > 0) {
      timeArr.push(`${hours} giờ`);
    }
    if (remainMinutes > 0) {
      timeArr.push(`${remainMinutes} phút`);
    }
    if (remainSeconds > 0) {
      timeArr.push(`${remainSeconds} giây`);
    }

    const hourString = timeArr.join(" ");
    let durationString = `${moment(val.created_at).format('DD/MM/YYYY HH:mm')} - ${moment(val.end_at).format('DD/MM/YYYY HH:mm')}`;
    if (val.status === 'cancel') {
      durationString = `${moment(val.created_at).format('DD/MM/YYYY HH:mm')}`;
    }
    return { durationString, hourString: val.status === 'cancel' ? '' : hourString };
  };

  const resolveFilterLayout = () => {
    const role = getUserRole();
    if (role.name === 'medical_facility') {
      return 4;
    }
    return 3;
  };

  return {
    analytics,
    item,
    columns,
    rows,
    doctor,
    medicalFacility,
    status,
    patient,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,

    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,


    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    resolveTimeLength,
    resolveFilterLayout,
  };
}
