<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="active"
      v-model="option"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="options"
      placeholder="Trạng thái"
      @input="valueChanged"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [Object, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    const items = [
      { title: 'Chờ khám', value: 'created' },
      { title: 'Đang khám', value: 'live' },
      { title: 'Đã khám', value: 'end' },
      { title: 'Hủy', value: 'cancel' },
    ];

    let item = this.defaultValue;
    const filters = items.filter(_obj => _obj.value === this.defaultValue);
    if (filters && filters.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      item = filters[0];
    }
    return {

      option: item,
      options: items,
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.option);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
